import axios from "axios";

class JobNotesAPI {

  jobId: string | undefined;

  setJobId(jobId: int | string) {
    this.jobId = jobId
  }

  /**
   * Show a list of the equipment items that are attached to this job that are attached do this
   * @param filters
   */
  index(filters: any = null) {
    let requestData = {}
    if (typeof filters !== 'undefined') {
      requestData = {
        params: filters
      }
    }
    return axios.get(`/jobs/${this.jobId}/notes`, requestData)
  }

  /**
   * Move an equipment item to a location
   * @param data
   */
  store(data: object) {
    return axios.post(`/jobs/${this.jobId}/notes`, data)
  }
  update(id: number | string, data: object) {
    return axios.put(`/jobs/${this.jobId}/notes/${id}`, data)
  }

  delete(id: number) {
    return axios.delete(`/jobs/${this.jobId}/notes/${id}`)
  }
}
export const jobNotesAPI = new JobNotesAPI()
