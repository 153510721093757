import axios from 'axios'
import { inputsToFormData } from '@/helpers'

export function uploadEquipmentImage (file: any) {
  return axios.post('/equipment-upload', file)
}

export function fetchEquipment (data: Record<string, any>) {
  let requestData
  if (typeof data !== 'undefined') {
    requestData = {
      params: data
    }
  }
  return axios.get('/equipment', requestData)
}

export function fetchSingleEquipment (id: number|string) {
  return axios.get('/equipment/' + id)
}

export function createEquipment (data: Record<string, any>) {
  return axios.post('/equipment', data)
}

export function updateEquipment (id: number|string, data: Record<string, any>) {
  return axios.post('/equipment/' + id, data)
}

export function deleteEquipment (id: number|string) {
  return axios.delete('/equipment/' + id)
}

export function fetchEquipmentCategories (id: number|string) {
  return axios.get('/equipment/categories')
}

export function fetchEquipmentBaseItems (id: number|string) {
  return axios.get('/equipment/base-items')
}

export function fetchEquipmentNonCombiItems () {
  return axios.get('/equipment/non-combi-items')
}

export function getEquipmentAtLocation (locationId: number|string) {
  return axios.get('/equipment/location/' + locationId)
}
