<script lang="ts" setup>
import {computed, defineEmits, inject, ref, watch} from 'vue';
import {jobEquipmentItemLocationAPI as API} from '@/api/jobs/jobEquipmentItemLocation'

import {useI18n} from 'vue-i18n'
import store from '@/store';
import {cloneDeep} from 'lodash-es';
import {formatDate} from '@vueuse/core'
import Say from '@/utils/Say'
import sayErrorResponse from '@/mixins/sayErrorResponse'
const { t } = useI18n()

const emit = defineEmits(["equipment-placed","show-template-ui","hide-template-ui"]);

const workOrderId = inject('workOrderId', null)
const workOrderLocationId = inject('workOrderLocationId', null)

// Injected from Job/View
const jobId = inject('jobId', null)
API.setJobId(jobId.value)

const fromLocationField = ref(null)
const equipmentLoading = ref(false)
const formSubmitted = ref(false)

const formatDate = inject('formatDate')
/**
 * van Amerongen Locations and the Available Equipment
 */
const vanAmerongenLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])
const equipmentAtLocation = ref([])
const equipmentAtLocationFormatted = computed(() => {
  return equipmentAtLocation.value.map((item) => {
    item.label = [item.name];
    if(item.unique_feature) {
      item.label.push(`| ${item.unique_feature}`)
      item.identifier = item.id;
    } else {
      item.identifier = item.equipment_id;
    }
    item.label.push(` (${item.count})`)
    item.label = item.label.join(' ');

    return item
  })
})

/**
 * This is the list of people that can supply equipment,
 * VA is always part of the list and at the top
 */
const suppliedByOptions = computed(() => {
  let options = [{id: null, name: t('OwnEquipment')}]
  for (const rentalSupplier of rentalSuppliers.value) {
    options.push({
      id: rentalSupplier.id,
      name: `${rentalSupplier.name} | ${ rentalSupplier.location.city}`,
    })
  }
  return options
})

const fromLocationOptions = computed(() => {
  let options = cloneDeep(vanAmerongenLocations.value)

  // Thi is a VA supplied
  if(!formData.value.supplier_id) {
    return options
  }
  // This is supplier supplied, attach the location from the selected supplier to the list

  let selectedSupplier = rentalSuppliers.value.filter((supplier) => supplier.id === formData.value.supplier_id)
  if(!selectedSupplier.length) {
    return options
  }
  selectedSupplier = selectedSupplier.pop()

  for(const option of options) {
    option.city = `VAF-Infra: ${option.city}`
  }

  options.unshift({
    id: selectedSupplier.location.id,
    city: selectedSupplier.location.location}
  )
  return options
});

const maxAmount = computed(() => {
  if(!formData.value.supplier_id) {
    if(selectedEquipment.value.composite_id) {
      return 100
    }
    return 250
  }
  return selectedEquipment.value.count
})

/**
 * Load the job and the locations from the Job Workorders
 */
const job = computed(() => store.getters['jobsEdit'])
const jobLocations = computed(() => {
  let locations = []
  let locationsIds: number[] = []
  const workOrders = job.value?.work_orders ? job.value.work_orders : []
  for (const workOrder of workOrders) {
    if(workOrder.location && !locationsIds.includes(workOrder.location.id)) {
      locationsIds.push(workOrder.location.id)
      locations.push(workOrder.location)
    }
  }
  return locations
})

const jobWorkOrders = computed(() => {
  let data = [];
  for (const workOrder of job.value?.work_orders ? job.value.work_orders : []) {
    let name = `${workOrder.standard_name_prefix} | ${workOrder.category}`
    if(workOrder.datetime_start) {
      name = `${formatDate(workOrder.datetime_start, ' ')} | ${name}`
    }
    data.push({
      name: name,
      id: workOrder.id
    })
  }
  return data
})

/**
 * The Equipment selected in the form
 */
const selectedEquipment = computed(() => {
  return equipmentAtLocation.value.filter((item) => {
    if(item.unique_feature) {
      return item.id === formData.value.equipmentId
    } else {
      return item.equipment_id === formData.value.equipmentId
    }
  }).pop() ?? {}
})

// Show the Create Form
const placeDialogVisible = inject('placeDialogVisible', false)


/**
 * The Form and all the references needed to keep track of that.
 */
const createForm = ref(null as HTMLFormElement | null)

// Keep track of the form validation state
const formValid = ref(false)

// The model keys being created/edited
const formData = ref({
  from_location_id : null,
  equipmentId: null,
  amount: null,
  location_id: workOrderLocationId,
  supplier_id: null,
  work_order_id: workOrderId,
  compositeUnique: {}
})

/**
 * Form validation rules
 */
const formRules = ref({
  from_location_id : [
    value => {
      if (value) return true
      return t('fromLocationRequired')
    },
  ],
  pleaseFillInMaterial : [
    value => {
      if (value) return true
      return t('pleaseFillInMaterial')
    },
  ],
  location_id: [
    value => {
      if (value) return true
      return t('pleaseFillInLocation')
    },
  ],
  equipmentId: [
    value => {
      if (value) return true
      return t('pleaseFillInMaterial')
    },
  ],
  amount: [
    value => {
      if(!value) {
        return t('pleaseFillInQuantity')
      }
      if(maxAmount.value && value > maxAmount.value) {
        if(formData.value.supplier_id) {
          return t('amountIsMoreThanAvailable')
        }
        return t('amountIsMoreThanAllowed', {max: maxAmount.value})
      }
      return true
    },
  ],
})

/**
 * Load correct equipment when a location is set
 */
watch(
  () => formData.value.from_location_id,
  () => {
    // make sure to remove any previously set equipment in the form
    formData.value.equipmentId = null
    formData.value.amount = null
    formData.value.location_id = workOrderLocationId
    formData.value.work_order_id = workOrderId

    if(formData.value.from_location_id) {
      loadEquipment()
    } else {
      equipmentAtLocation.value = []
    }
  })

watch(
  () => formData.value.supplier_id,
  () => {
    fromLocationField.value.reset()
  }
)

watch(
  () => formData.value.equipmentId,
  () => {
    if (selectedEquipment.value.unique_feature || selectedEquipment.value.hasUnique) {
      formData.value.amount = 1
    } else {
      formData.value.amount = null
    }
    formData.value.compositeUnique = {}
  }
)

/**
 * When the dialog is opened then load van Amerongen Locations, just in case it is not yet loaded
 */
watch(
  () => placeDialogVisible.value,
  () => {
    if(placeDialogVisible.value) {
      store.dispatch('cache.vanAmerongenLocations.fetch');
      store.dispatch('rentalSuppliers.fetch');

      if(fromLocationOptions.value.length > 0 && !formData.value.from_location_id) {
        if(job.value?.location_va?.id) {
          formData.value.from_location_id = job.value?.location_va?.id
        }
      }
    } else {
      fromLocationField.value.reset()
    }
  }
)

const loadEquipment = () => {
  equipmentLoading.value = true
  API.getEquipmentAtLocation(formData.value.from_location_id, formData.value.supplier_id)
    .then((response) => {
      equipmentAtLocation.value = response.data
      equipmentLoading.value = false
    })
    .catch((error) => {
      alert(error)
    })
}

/**
 * Save data to the server
 */
const submitForm = () => {
  if (formSubmitted.value) return
  formSubmitted.value = true

  if(!formData.value.from_location_id) {
    formValid.value = false
  }
  if(!formData.value.equipmentId) {
    formValid.value = false
  }
  if(!formData.value.amount) {
    formValid.value = false
  }
  if(!formData.value.location_id) {
    formValid.value = false
  }

  // Make sure that the form is valid
  if (!formValid.value) {
    formSubmitted.value = false
    return
  }

  // Clone the form Data
  let data = cloneDeep(formData.value);

  if(selectedEquipment.value.composite_id) {
    data.is_composite = true
  }
  if(selectedEquipment.value.no_loc) {
    data.no_loc = true
  }
  if(selectedEquipment.value.unique_feature) {
    data.equipment_id = null
    data.equipment_item_id = selectedEquipment.value.id
  } else {
    data.equipment_id = selectedEquipment.value.equipment_id
    data.equipment_item_id = null
  }

  // Add additional Items that is needed
  data.job_id = jobId?.value
  data.location_change_type = 'place'

  API.store(data).then((response) => {
      // reset the form
      createForm.value.reset()

      // emit the created event
      emit("equipment-placed");

      // Close the form and show the created message
      Say('success', t('dialogMessagePlaced'))
      placeDialogVisible.value = false
      formSubmitted.value = false
  })
    .catch((error) => {
      formSubmitted.value = false
      sayErrorResponse(error.response?.data?.message ?? error.message)
    })
}

const amountChange = ($event) => {
  if(!maxAmount.value) return
  if($event.target.value > maxAmount.value) {
    // $event.target.value = maxAmount.value
    // formData.value.amount = maxAmount.value
  }
}

const extractLocation = (locationId) => {
  return fromLocationOptions.value.filter((location) => location.id === locationId)[0]
}

</script>
<template>
  <div>
    <v-dialog
      v-model="placeDialogVisible"
      width="800"
      @afterLeave="createForm.reset()"
    >
      <v-form
        v-model="formValid"
        ref="createForm"
        autocomplete="off"
        @submit.prevent="submitForm"
      >
        <v-card
          max-width="800"
          prepend-icon="mdi-pencil"
          :title="t('PlaceEquipment')"
        >
          <template v-slot:text>

            <v-row>
              <v-col sm="6">
                <v-autocomplete
                  v-model="formData.supplier_id"
                  :rules="formRules.supplier_id"
                  :items="suppliedByOptions"
                  id="form-supplier_id"
                  :label="t('EquipmentSuppliedBy')"
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                />
              </v-col>
              <v-col sm="6">
                <v-autocomplete
                  v-model="formData.from_location_id"
                  :rules="formRules.from_location_id"
                  :items="fromLocationOptions"
                  ref="fromLocationField"
                  id="form-from_location_id"
                  :label="t('fromLocation')"
                  item-title="city"
                  item-value="id"
                  variant="underlined"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="6">
                <v-autocomplete
                  v-model="formData.equipmentId"
                  :rules="formRules.equipmentId"
                  id="form-equipmentId"
                  :items="equipmentAtLocationFormatted"
                  :label="t('equipment')"
                  :disabled="equipmentLoading || !formData.from_location_id"
                  :loading="equipmentLoading"
                  item-title="label"
                  item-value="identifier"
                  variant="underlined"
                  @change=""
                />
              </v-col>
              <v-col
                v-if="selectedEquipment.composite_id"
                sm="6"
              >
                <template
                  v-for="(equipmentItems, equipmentId) in selectedEquipment.hasUnique"
                >
                  <v-autocomplete
                    v-model="formData.compositeUnique[equipmentId]"
                    :items="selectedEquipment.hasUnique[equipmentId].items"
                    :label="selectedEquipment.hasUnique[equipmentId].name"
                    :rules="formRules.pleaseFillInMaterial"
                    item-title="label"
                    item-value="id"
                    variant="underlined"
                  />
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="8">
               <v-autocomplete
                  v-model="formData.work_order_id"
                  id="form-work_order_id"
                  clearable
                  :items="jobWorkOrders"
                  :label="t('workOrder')"
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                  @change=""
                  autocomplete="off"
                  :disabled="!!workOrderId && formData.work_order_id === workOrderId"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="8">
                <v-autocomplete
                  v-model="formData.location_id"
                  :rules="formRules.location_id"
                  id="form-location_id"
                  :items="jobLocations"
                  :label="t('PlaceEquipmentAtLocation')"
                  item-title="location"
                  item-value="id"
                  variant="underlined"
                  @change=""
                  :disabled="!!workOrderLocationId && formData.location_id === workOrderLocationId"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  v-if="!selectedEquipment.unique_feature && !selectedEquipment.hasUnique"
                  v-model="formData.amount"
                  id="form-amount"
                  :rules="formRules.amount"
                  :label="t('Amount')"
                  variant="underlined"
                  min="1"
                  :max="maxAmount"
                  type="number"
                  @change="amountChange($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <template v-if="1===0 && (formData.amount <= maxAmount) && selectedEquipment.count !== null && selectedEquipment.count < formData.amount">
              <v-alert
                :text="t('warningStockWillBeAddedToLocation')"
                type="warning"
              ></v-alert>
            </template>

          </template>
          <template v-slot:actions>
            <v-btn
              :text="t('save')"
              :disabled="!formValid"
              type="submit"
            />
            <v-btn
              :text="t('cancel')"
              type="reset"
              @click="placeDialogVisible = false"
            />
          </template>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
#composition-table {
  width: 100%;
  th {
    text-align: left;
    width: 33%;
  }
  th, td {
    padding:0 10px;
  }
}
</style>
