<template>
  <v-row v-if="false">
    <v-col sm="12" class="pb-0">
      <v-data-table-server
        :headers="headers"
        :items="jobsList"
        :single-expand="true"
        :items-length="jobsListTotalCount"
      >
        <template v-slot:headers>
          <tr>
            <template v-for="header in headers" :key="header.key" >
              <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                <span class="cursor-pointer font-weight-bold">{{ capitalcase(header.title) }}</span>
              </th>
            </template>
          </tr>
        </template>
        <template v-slot:item="{ item, internalItem, isExpanded, toggleExpand } : {item: any, internalItem: any, isExpanded: (item: any) => boolean, toggleExpand: (item: any) => void}">
           <tr :class="isExpanded(internalItem) ? 'expanded-row' : ''">
            <td>
                <v-icon @click="toggleExpand(internalItem)">{{ getExpandIcon(isExpanded(internalItem)) }}</v-icon>
            </td>
            <td>
              {{ item.job_name }}
            </td>
           </tr>
        </template>
        <template  v-slot:expanded-row="{ item } : {item : any}">
          <template v-for="(e, i) in item.work_orders">
            <tr class="bg_custom_table_row">
              <td>=== {{ item.job_name }} ===</td>

                <td>{{ e.standard_name_prefix }}</td>

              <td></td>
            </tr>
          </template>
        </template>
      </v-data-table-server>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" class="pb-0">
      <v-card flat elevation="4">
        <v-card-title class="py-0">

          <v-chip-group
            v-model="selectedStatusIndexes"
            mandatory
            multiple
            selected-class="bg-blue-darken-1"
            class="align-center"
          >
            <h4 class="pr-5 pt-2">
              Filters
            </h4>
            <v-chip v-for="filterOption in filterStatuses"
              :key="filterOption"
              flat
            >
              {{ t(`jobStatuses.${filterOption}` , '') }}
            </v-chip>
          </v-chip-group>
          <!-- Right aligned things -->
        </v-card-title>
      </v-card>
    </v-col>

    <v-col sm="12" class="pb-0 pt-0">
      <v-card flat elevation="4">
        <v-card-title class="pa-0">
          <v-table class="ma-0 pl-2">
              <th style="width: 40px; vertical-align: middle;" class="py-0">
                {{ t('search') }}
              </th>
              <th v-for="header in searchHeaders" :key="header.key"  class="py-0" :style="{width: `${header.width}px !important`}" >
                <v-text-field
                  v-if="header.search"
                  v-model="searchValue[(header.search as string)]"
                  :placeholder="header.title"
                  append-inner-icon="mdi-magnify"
                  clearable
                  @click.stop
                  @keydown.space.stop
                  variant="underlined"
                  class="px-2 pb-4"
                  hide-details
                  @update:modelValue="val => debouncedSearch(header.search as string, val)"
                />
                <span v-else>{{ header.title }}</span>
              </th>
          </v-table>
        </v-card-title>
      </v-card>
    </v-col>

    <v-col sm="12" class="pt-0">
      <v-card flat>
        <v-card-text class="px-0 pt-0">
          <v-data-table-server
            :headers="headers"
            :items="jobsList"
            :loading="jobsListLoading"
            :items-per-page-text="rowsPerPageText"
            :items-per-page-options="rowsPerPageItems"
            v-model:items-per-page="pagination.rowsPerPage"
            v-model:page="pagination.page"
            :items-length="jobsListTotalCount"
            :single-expand="true"
            sort-asc-icon="mdi-sort-ascending"
            sort-desc-icon="mdi-sort-descending"
            show-expand
            @update:sort-by="sortEvent"
          >
            <template v-slot:headers="{isSorted, getSortIcon, toggleSort }">
              <tr>
                <template v-for="header in headers" :key="header.key" >
                  <th class="custom_header_table custom-bg" :style="{width: `${header.width}px !important`}" >
                    <span class="d-flex align-center" @click="header.sortable ? toggleSort(header) : ''">
                      <span :class="header.sortable ? 'pointer' : ''" class="font-weight-bold">{{ capitalcase(header.title) }}</span>
                      <template v-if="isSorted(header)">
                        <v-icon :icon="getSortIcon(header)" size="small" style="margin: 5px"></v-icon>
                      </template>
                      <template v-if="header.sortable && !isSorted(header)">
                        <v-icon icon="mdi-sort" size="small" style="margin: 5px"></v-icon>
                      </template>
                    </span>
                  </th>
                </template>
              </tr>
            </template>

            <template v-slot:item="{ item, internalItem, isExpanded, toggleExpand } : {item: any, internalItem: any, isExpanded: (item: any) => boolean, toggleExpand: (item: any) => void}">
              <tr :class="isExpanded(internalItem) ? 'expanded-row' : ''">
                <td class="expand-toggle">
                  <v-icon
                    v-if="item.work_orders.length"
                    @click="toggleExpand(internalItem)">{{ getExpandIcon(isExpanded(internalItem)) }}</v-icon>
                </td>
                <td>
                  <v-menu
                    location="end center"
                    :offset="10"
                    open-on-hover
                  >
                    <template v-slot:activator="{ props, isActive }">
                      <v-btn
                        v-bind="props"
                        icon
                        size="small"
                      >
                        <v-icon :class="{'reset-icon': !isActive , 'convert-icon': isActive}">{{!isActive ? 'mdi-pencil' : 'mdi-close' }}</v-icon>
                      </v-btn>
                    </template>

                    <v-row class="bg-grey-lighten-2 rounded-xl">
                      <v-tooltip
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            v-bind="props"
                            @click.stop="router.push({ name: 'JobsEdit', params: { id: item.id }})"
                            icon
                            size="small"
                            color="grey"
                            class="mr-1 second show-icon"
                          >
                            <v-icon>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>
                      <v-tooltip
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            v-bind="props"
                            @click.stop="deleteJob(item.id)"
                            icon
                            size="small"
                            color="red"
                            class="third show-icon"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-menu>
                </td>
                <td class="ws-nowrap">
                  <router-link
                    class="custom-a"
                    :to="{ name: 'JobsView', params: { id: item.id } }"
                    :style="{width: `${headerWidth('job_name')}px !important`}"
                  >
                    {{ item.job_name }}
                </router-link>
                </td>
                <td>
                  <v-tooltip
                    location="top"
                    :text="item.job_description"
                    :max-width="headerWidth('job_description')"
                    open-delay="300"
                  >
                    <template v-slot:activator="{ props }">
                      <span v-bind="props" class="ws-nowrap ws-ellipsis d-inline-block" v-if="item.job_description" :style="{width: `${headerWidth('instructions')}px !important`}">
                        <span>{{ item.job_description }}</span>
                      </span>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip
                    location="top"
                    :text="item.instructions"
                    :max-width="headerWidth('instructions')"
                    open-delay="300"
                  >
                    <template v-slot:activator="{ props }">
                      <span v-bind="props" class="ws-nowrap ws-ellipsis d-inline-block" v-if="item.instructions" :style="{width: `${headerWidth('instructions')}px !important`}">
                        <span>{{ item.instructions }}</span>
                      </span>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip
                    v-if="item.client"
                    location="top"
                    :text="item.client ? clientNameAndAddress(item) : ''"
                    :max-width="headerWidth('clients.name')"
                    open-delay="300"
                  >
                    <template v-slot:activator="{ props }">
                      <router-link class="custom-a ws-ellipsis d-inline-block" :style="{width: `${headerWidth('clients.name')}px !important`}" :to="{ name: 'ClientsEdit', params: { id: item.client.id } }" v-bind="props">
                        <span class="ws-nowrap">{{ item.client ? item.client.name : '' }}</span>
                      </router-link>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  <div v-if="item.client_responsible_1" class="ws-nowrap">
                    <template v-if="item.client_responsible_1.phone">
                      <v-tooltip bottom v-slot:activator>
                        <span >{{ item.client_responsible_1.name }} </span>
                        <div><v-icon size="small" class="mr-1">mdi-phone</v-icon>{{ item.client_responsible_1.phone }}</div>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{ item.client_responsible_1.name }}
                    </template>
                  </div>
                  <div v-if="item.client_responsible_2" class="ws-nowrap">
                    <template v-if="item.client_responsible_2.phone">
                      <v-tooltip bottom v-slot:activator>
                        <span >{{ item.client_responsible_2.name }} </span>
                        <div><v-icon size="small" class="mr-1">mdi-phone</v-icon> {{ item.client_responsible_2.phone }}</div>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{ item.client_responsible_2.name }}
                    </template>
                  </div>
                </td>
                <td class="date">
                  <span class="date ws-nowrap ml-0">
                    <template v-if="item.work_orders.length">
                      {{ prettyDateFormat(getStartDateFromWorkOrders(item)) }}
                    </template>
                  </span>
                </td>
                <td>
                  <v-tooltip
                    location="top"
                    :text="item.location ? item.location.location : ''"
                    :max-width="headerWidth('location')"
                    open-delay="300"
                  >
                    <template v-slot:activator="{ props }">
                      <span v-bind="props" class="ws-nowrap ws-ellipsis d-inline-block" v-if="item.location" :style="{width: `${headerWidth('location')}px !important`}">
                        <span>{{ item.location.location }}</span>
                      </span>
                    </template>
                  </v-tooltip>
                </td>
                <td>
                  {{ item.responsible ? item.responsible.name : '' }}
                </td>
                <td>
                  {{ t(`jobStatuses.${item.status.name}`) }}<!--
                  NO WHITESPACE
                  --><span v-if="item.status === 'ONGOING' && item.status_ongoing">/ {{ t('jobStatusesOngoing.' + item.status_ongoing.name ) }}</span>
                </td>
              </tr>
            </template>

            <template  v-slot:expanded-row="{ item, internalItem, isExpanded } : {item: any, internalItem: any, isExpanded: (item: any) => boolean}">
              <template v-for="(order) in item.work_orders">
                <tr :class="isExpanded(internalItem) ? 'expanded-row-subrow' : ''">
                  <td></td>
                  <td>
                    <v-menu
                    location="end center"
                    :offset="10"
                    open-on-hover
                  >
                    <template v-slot:activator="{ props, isActive }">
                      <v-btn
                        v-bind="props"
                        icon
                        size="small"
                      >
                        <v-icon :class="{'reset-icon': !isActive , 'convert-icon': isActive}">{{!isActive ? 'mdi-pencil' : 'mdi-close' }}</v-icon>
                      </v-btn>
                    </template>

                    <v-row class="bg-grey-lighten-2 rounded-xl">
                      <v-tooltip
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            v-bind="props"
                            @click.stop="router.push({ name: 'WorkOrdersEdit', params: { id: order.id }})"
                            icon
                            size="small"
                            color="grey"
                            class="mr-1 second show-icon"
                          >
                            <v-icon>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>
                      <v-tooltip
                        location="top"
                      >
                        <template v-slot:activator="{ props }">
                          <v-btn
                            v-bind="props"
                            @click.stop="deleteWorkOrder(order.id)"
                            icon
                            size="small"
                            color="red"
                            class="third show-icon"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>
                    </v-row>
                  </v-menu>
                  </td>
                  <td class="ws-nowrap">
                    <router-link
                      class="custom-a"
                      :to="{ name: 'WorkOrdersView', params: { id: order.id } }"
                      :style="{width: `${headerWidth('job_name')}px !important`}"
                    >
                      {{ order.standard_name_prefix }}
                    </router-link>
                  </td>
                  <td></td>
                  <td>
                    <v-tooltip
                      location="top"
                      :text="order.instructions"
                      :max-width="headerWidth('instructions')"
                      open-delay="300"
                    >
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="ws-nowrap ws-ellipsis d-inline-block" v-if="order.instructions" :style="{width: `${headerWidth('instructions')}px !important`}">
                        <span>{{ order.instructions }}</span>
                      </span>
                      </template>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip
                      location="top"
                      :text="order.client ? clientNameAndAddress(order) : ''"
                      :max-width="headerWidth('clients.name')"
                      open-delay="300"
                    >
                      <template v-slot:activator="{ props }">
                        <router-link class="custom-a ws-ellipsis d-inline-block" :style="{width: `${headerWidth('clients.name')}px !important`}" :to="{ name: 'ClientsEdit', params: { id: order.client.id } }" v-bind="props">
                          <span class="ws-nowrap">{{ order.client ? order.client.name : '' }}</span>
                          <template v-if="order.project_name && order.project_name.trim()">
                            <br>
                            <span class="ws-nowrap"><small>{{ order.project_name.trim() }}</small></span>
                          </template>
                        </router-link>
                      </template>
                    </v-tooltip>
                  </td>
                  <td>
                    <div v-if="order.client_responsible_1" class="ws-nowrap">
                      <template v-if="order.client_responsible_1.phone">
                        <v-tooltip bottom v-slot:activator>
                          <span >{{ order.client_responsible_1.name }} </span>
                          <div><v-icon size="small" class="mr-1">mdi-phone</v-icon>{{ order.client_responsible_1.phone }}</div>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        {{ order.client_responsible_1.name }}
                      </template>
                    </div>
                    <div v-if="order.client_responsible_2" class="ws-nowrap">
                      <template v-if="order.client_responsible_2.phone">
                        <v-tooltip bottom v-slot:activator>
                          <span >{{ order.client_responsible_2.name }} </span>
                          <div><v-icon size="small" class="mr-1">mdi-phone</v-icon> {{ order.client_responsible_2.phone }}</div>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        {{ order.client_responsible_2.name }}
                      </template>
                    </div>
                  </td>
                  <td>
                    <span class="date ws-nowrap ml-0">
                      <template v-if="order.datetime_start">
                        {{ prettyDateFormat(order.datetime_start) }}
                      </template>
                    </span>
                  </td>
                  <td>
                    <v-tooltip
                      location="top"
                      :text="order.location ? order.location.location : ''"
                      :max-width="headerWidth('location')"
                      open-delay="300"
                    >
                      <template v-slot:activator="{ props }">
                        <span v-bind="props" class="ws-nowrap ws-ellipsis d-inline-block" v-if="order.location" :style="{width: `${headerWidth('location')}px !important`}">
                          <span>{{ order.location.location }}</span>
                        </span>
                      </template>
                    </v-tooltip>
                  </td>
                  <td>
                    {{ order.responsible ? order.responsible.name : '' }}
                  </td>
                  <td>
                    {{ t(`jobStatuses.${order.status.name}`) }}<!--
                    NO WHITESPACE
                    --><span v-if="order.status === 'ONGOING' && order.status_ongoing">/ {{ t('jobStatusesOngoing.' + order.status_ongoing.name ) }}</span>
                  </td>
                </tr>
              </template>
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es'
import Say from '@/utils/Say'

import { datatableOptions } from '@/mixins/datatableOptions'
import { prettyDateFormat, capitalcase } from '@/utils/filters'
import { computed, defineComponent, ref, watch } from 'vue'
import { onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import useBranchSelector from '@/composable/useBranchSelector'
import { computedAsync } from '@vueuse/core'
import { defineEmits } from 'vue';
import emitArray from "@/utils/emit";
import sayErrorResponse from '@/mixins/sayErrorResponse'

const { branchSelected } = useBranchSelector()
const { rowsPerPageText, rowsPerPageItems, pagination } = datatableOptions(false)
const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const alreadyRetrieved = ref(false)

const isSmAndDown = ref(false); // Initialize with a default value

// Update the isSmAndDown value based on screen size
const handleResize = () => {
  isSmAndDown.value = window.innerWidth <= 1537; // Adjust the breakpoint value as needed
};

window.addEventListener('resize', handleResize);
handleResize();

const emit = defineEmits(emitArray.value)

const headers: any = ref([
  { width: isSmAndDown.value ? 10 : 10, key: '' },
  { width: isSmAndDown.value ? 40 : 40, key: '' },
  { width: isSmAndDown.value ? 120 : 120, title: t('name', 1), key: 'job_name', sortable: true, search: 'job_name' },
  { width: isSmAndDown.value ? 100 : 100, title: t('description', 1), key: 'job_description', sortable: false, search: 'job_description' },
  { width: isSmAndDown.value ? 100 : 150, title: t('instructions'), key: 'instructions', sortable: false, search: 'instructions' },
  { width: isSmAndDown.value ? 100 : 150, title: t('client', 2), key: 'clients.name', sortable: true, search: 'clients_name' },
  { width: isSmAndDown.value ? 100 : 50, title: t('contact', 1), key: 'contact', sortable: false, search: 'contacts' },
  { width: isSmAndDown.value ? 100 : 50, title: t('start'), key: 'datetime_start', sortable: false, search: false },
  { width: isSmAndDown.value ? 100 : 150, title: t('location', 1), key: 'location', sortable: false, search: 'location' },
  { width: isSmAndDown.value ? 50 : 50, title: t('responsible'), key: 'employees.name', sortable: true, search: 'responsible' },
  { width: isSmAndDown.value ? 70 : 90, title: t('status'), key: 'status', sortable: false, search: false }
])

const searchHeaders: any = ref([
  { width: isSmAndDown.value ? 80 : 80, title: t('name', 1),  search: 'job_name' },
  { width: isSmAndDown.value ? 85 : 150, title: t('description', 1),  search: 'job_description' },
  { width: isSmAndDown.value ? 85 : 150, title: t('instructions'),  search: 'instruction' },
  { width: isSmAndDown.value ? 85 : 150, title: t('client', 2),search: 'clients_name' },
  { width: isSmAndDown.value ? 100 : 140, title: t('contact', 1),search: 'contacts' },
  { width: isSmAndDown.value ? 100 : 100, title: '', search: false },
  { width: isSmAndDown.value ? 80 : 150, title: t('location', 1), search: 'location' },
  { width: isSmAndDown.value ? 70 : 140, title: t('responsible'), search: 'responsible' },
  { width: isSmAndDown.value ? 50 : 50, title: '', search: false }
])

const filterStatuses= ref([
  'PLANNED',
  'ONGOING',
  'COMPLETED',
  'CANCELLED'
])

const getStartDateFromWorkOrders = (item: any) => {
  let earliestDate = null;
  for (const wo of item.work_orders) {
    if(earliestDate === null || wo.datetime_start < earliestDate) {
      earliestDate = wo.datetime_start
    }
  }
  return earliestDate
}

const clientNameAndAddress = (item: any) => {
  let clientName = '';
  let clientAddress = '';
  let clientCity = '';

  if (item.client && item.client.name && item.client.name.trim()) {
    clientName = item.client.name
    clientAddress = item.client.address ? item.client.address : '';
    clientCity = item.client.city ? item.client.city : '';

    if (clientAddress && clientCity) {
      clientAddress = `${clientAddress}, ${clientCity}`
    }
  }

  return clientName && clientAddress
    ? `${clientName}\n${clientAddress}`
    : clientName;
}

const selectedStatusIndexes = ref((
  window.localStorage.getItem('jobs/List/selectedStatusIndexes')
  ? window.localStorage.getItem('jobs/List/selectedStatusIndexes')!.split(',').map(i => parseInt(i))
  : [0, 1, 2]
))
watch(selectedStatusIndexes, (value) => {
  window.localStorage.setItem('jobs/List/selectedStatusIndexes', value.join(','))
})

const getExpandIcon = (isExpanded: boolean) => {
  if (isExpanded) {
    return 'mdi-chevron-up'
  } else {
    return 'mdi-chevron-down'
  }
};

const jobsListLoading = computed(() => {
  return store.getters.jobsListLoading
})
const jobsListTotalCount = computed(() => {
  return store.getters.jobsListTotalCount
})

const selectedStatuses = computed(() => {
  return selectedStatusIndexes.value.map(
    index => filterStatuses.value[index]
  )
})

// Immediately updated model
const searchValue = ref<Record<string, string>>(searchHeaders.value.reduce((acc: any, val: any) => {
  if (!val.search) {
    return acc
  }

  acc[val.search] = ''
  return acc
}, {}))

// Model updated on a debounce
const search = ref<Record<string, string>>({})
const debouncedSearch = debounce((searchField: string, val) => {
  search.value[searchField] = val
}, 500)

const jobsList = computedAsync(async () => {
  let payload : { [key: string]: any } = {
    ...pagination.value,
    showHistoric: true,
    status: selectedStatuses.value.join(',')
  }

  for (const [key, value] of Object.entries(search.value)) {
    if (value) {
      payload[`s-${key}`] = value
    }
  }

  return await store.dispatch('jobs.fetch', payload)
})

if(route.query) {
  for (const [key, value] of Object.entries(route.query)) {
    if (value && !Array.isArray(value)) {
      searchValue.value[key] = value
    }
  }
  router.replace({ query: {} })
}

onBeforeMount(() => {
  // UI
  store.commit('app.setPageTitle', t('job', 2))
  store.commit('app.setBreadcrumbs', [
    { text: t('job', 2) }
  ])

  // getJobs()
})

const deleteJob = (id: number) => {
  let confirmAction = confirm(t('jobDeleteConfirm'))
  if (confirmAction) {
    store.dispatch('jobs.delete', id).then(() => {
      Say('success', t('jobDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}
const deleteWorkOrder = (id: number) => {
  // TODO remove from list
  let confirmAction = confirm(t('workOrderDeleteConfirm'))
  if (confirmAction) {
    store.dispatch('workOrders.delete', id).then(() => {
      Say('success', t('workOrderDeleteSuccess'))
    }).catch((err) => {
      sayErrorResponse(err)
    })
  }
}

const sortEvent = (value: any) => {

  if(value.length === 0) {
    return
  }

  pagination.value.sortBy = value[0].key
  pagination.value.descending = value[0].order === 'desc' ? true : false

  // done by watch(pagination)
  // getJobs()
}

const headerWidth = (key: string) => {
  const header = headers.value.find((header: any) => header.key === key)
  if (header) {
    alreadyRetrieved.value = true
    return header.width
  }
}
</script>

<style lang="scss" scoped>
* {
  table.table {
    td {
      line-height: 20px;
    }

    td,
    th {
      padding: 5px 10px !important;
      position: relative;

      &.date {
        padding: 0 10px !important;
      }
    }

    th {
      vertical-align: top;

      .icon:not(.input-group__icon-clearable) {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.expanded-row td {
  background-color: var(--custom-bg-row) !important;
}
.expanded-row td button,
.expanded-row-subrow td button {
  background-color: rgb(240, 246, 255) !important;
}
.expanded-row-subrow td {
  background-color: var(--custom-bg-subrow) !important;
}
.expanded-row-subrow:hover td {
  background-color: var(--custom-bg-row) !important;
}

.v-col.pb-0 {
  padding-bottom: 10px !important;
}

.speed-dial {
  .btn {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;

    + .btn {
      margin-left: .3rem;
    }
  }

  .speed-dial__list {
    z-index: 999;
    background: #e0e0e0;
    border-radius: 1rem;
    height: 2.5rem;
  }

  .icon {
    font-size: 1.3rem;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

.employee:not(:last-child) {
  &:after {
    content: ', ';
  }
}

.show-icon {
  &.first {
    animation: showIcon .1s linear;
  }
  &.second {
    animation: showIcon .12s linear .05s;
  }

  &.third {
    animation: showIcon .15s linear .05s;
  }
}

.convert-icon {
  animation: convertIcon .1s linear;
  // animation: name duration timing-function delay iteration-count direction fill-mode;
  &:hover {
    animation: convertIcon .1s linear;
  }
}

.reset-icon {
  animation: resetIcon .1s linear;
}

.custom_header_table {
  font-weight: 400 !important;
  vertical-align: baseline !important;
  padding: 10px 10px 0 10px!important;
}

tbody td {
  padding: 0 10px !important;
}
tbody td.expand-toggle {
  padding: 0 0 0 10px !important;
}
tbody td.date {
  min-width:115px;
}
@keyframes showIcon {
  0% {
    opacity: 0;
    left: -50px;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes convertIcon {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes resetIcon {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
