<script setup lang="ts">
import {getCurrentInstance, inject, onMounted, provide, ref} from 'vue';
import {jobNotesAPI as API} from "@/api/jobs/JobNotes";
import {ucfirst, fromStorage, formatBytes, prettyDateFormatWithoutHours, hourMinutesFromDateTime} from '@/utils/filters'
import {useI18n} from 'vue-i18n'
import Preview from "@/components/UI/Documents/Preview.vue";
import Say from "@/utils/Say";
import sayErrorResponse from "@/mixins/sayErrorResponse";

// The string  translator
const {t} = useI18n()

const notes = ref([])

const noteForm = ref(null as HTMLFormElement | null)
const noteFormValid = ref(false)

const isEditingNoteId = ref(null)
const noteFormTitle = ref(null)
const noteFormContent = ref(null)
const noteFormRules = ref({
  title: [
    value => {
      if (value) return true
      return t('pleaseFillInTitle')
    },
  ],
  content: [
    value => {
      if (value) return true
      return t('pleaseFillInContent')
    },
  ],
})

const noteFormDialog = ref(false)

const jobId = inject('jobId', null)
API.setJobId(jobId.value)

onMounted(() => {
  getNotes()
})

const getNotes = () => {
  API.index()
    .then((response) => {
      notes.value = response.data
    })
    .catch((error) => {
      console.error(error)
    })
}
const submitForm = () => {
  noteForm.value.validate()

  // Make sure that the form is valid
  if (!noteFormValid.value) {
    return
  }

  if (isEditingNoteId.value) {
    API.update(isEditingNoteId.value, {
      title: noteFormTitle.value,
      content: noteFormContent.value,
    })
      .then((response) => {
        Say('success', t('jobNoteEditSuccess', 1))
        cancelForm()
        getNotes()
      })
      .catch((error) => {
        sayErrorResponse(error.response?.data?.message ?? error.message)
      })
  } else {
    API.store({
      title: noteFormTitle.value,
      content: noteFormContent.value,
    })
      .then((response) => {
        Say('success', t('jobNoteCreateSuccess', 1))
        cancelForm()
        getNotes()
      })
      .catch((error) => {
        sayErrorResponse(error.response?.data?.message ?? error.message)
      })
  }
}

const deleteNote = (note) => {
  if (!confirm(t('jobNoteDeleteConfirmation', 1))) {
    return
  }
  API.delete(note.id)
    .then((response) => {
      Say('success', t('jobNoteDeletedSuccess', 1))
      cancelForm()
      getNotes()
    })
    .catch((error) => {
      sayErrorResponse(error.response?.data?.message ?? error.message)
    })
}

const cancelForm = () => {
  noteFormDialog.value = false
  noteFormTitle.value = null
  noteFormContent.value = null
  isEditingNoteId.value = null
}
const openForm = (note = null) => {
  if (note) {
    noteFormTitle.value = note.title
    noteFormContent.value = note.content
    isEditingNoteId.value = note.id
  } else {
    noteFormTitle.value = null
    noteFormContent.value = null
    isEditingNoteId.value = null
  }
  noteFormDialog.value = true
}

const getImagePath = (note, image: any) => {
  if (note.work_order_id) {
    return `/storage/workOrderNotes/${image}`
  }
  return null
}


let isPreviewing = ref()
provide('previewFile', isPreviewing)

const showImage = (url: string, name: string) => {
  isPreviewing.value = {
    url: url,
    name: name,
    extension: null
  }
}

const previewClosed = () => {
  isPreviewing.value = false
}
const getNoteSubtitle = (note: any) => {
  const [date] = note.updated_at.split('T')

  const at = t('at')
  const by = t('by')

  return `${prettyDateFormatWithoutHours(date)} ${at} ${hourMinutesFromDateTime(note.updated_at)} ${by} ${note.name}`
}

</script>

<template>
  <div>
    <Preview @close="previewClosed"/>

    <v-row>
      <v-col cols="12">
        <v-btn :text="t('addNote')" @click="openForm"/>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="note in notes" cols="12" md="6" lg="3" class="d-flex pa-1">
        <v-card
          class="w-100 job-note-card"
          :class="{'isWorkOrder': note.work_order_id}"
        >
          <template v-slot:title>
            <p class="title font-weight-bold">{{ note.title }}</p>
            <p class="subtitle py-1">{{ getNoteSubtitle(note) }}</p>
          </template>
          <template v-slot:text>

            <div class="note-toolbar text-right">

              <v-menu
                v-if="note.id && !note.work_order_id"
                location="start center"
                :offset="10"
                open-on-hover
              >
                <template v-slot:activator="{ props, isActive }">
                  <v-btn
                    v-bind="props"
                    icon="mdi-pencil"
                    size="x-small"
                  >
                    <v-icon :class="{'reset-icon': !isActive , 'convert-icon': isActive}">{{!isActive ? 'mdi-pencil' : 'mdi-close' }}</v-icon>
                  </v-btn>
                </template>
                <v-row class="bg-grey-lighten-2 rounded-xl pa-0">
                  <v-btn
                    icon="mdi-pencil"
                    class="ma-1"
                    size="x-small"
                    @click.stop="openForm(note)"
                  ></v-btn>
                  <v-btn
                    icon="mdi-delete"
                    class="ma-1"
                    color="red"
                    size="x-small"
                    @click.stop="deleteNote(note)"
                  ></v-btn>
                </v-row>

              </v-menu>

              <a v-if="note.work_order_id"
                 class="right ml-1"
                 target="_blank"
                 :href="`/work-orders/view/${note.work_order_id}`"
              >
                {{ note.work_order_name }}
              </a>
            </div>

            {{ note.content }}
            <div v-if="note.work_order_id && note.images">
              <v-row class="ga-0 ma-0 mt-2">
                <v-col
                  cols="12"
                  md="3"
                  v-for="image in note.images"
                  class="d-flex child-flex ga-0 pa-1"
                >
                  <div :style="{'background-image': `url(${fromStorage(getImagePath(note, image))})`}"
                       class="wo-note-image"
                       @click="showImage(fromStorage(getImagePath(note, image)), image)"
                  />
                </v-col>
              </v-row>
            </div>
          </template>
        </v-card>
      </v-col>
    </v-row>


    <v-dialog
      v-model="noteFormDialog"
      width="400"
    >
      <v-form
        v-model="noteFormValid"
        ref="noteForm"
        @submit.prevent="submitForm"
      >
        <v-card
          max-width="400"
          prepend-icon="mdi-pencil"
          :title="$t('add')"
        >
          <template v-slot:text>
            <v-text-field
              v-model="noteFormTitle"
              :rules="noteFormRules.title"
              placeholder="$t('title')"
              :label="$t('title')"
              variant="underlined"
            />

            <v-textarea
              v-model="noteFormContent"
              :rules="noteFormRules.content"
              placeholder="$t('content')"
              :label="$t('content')"
              variant="underlined"
            />
          </template>
          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn :text="$t('cancel')" @click="cancelForm"/>
            <v-btn :text="$t('save')" @click="submitForm"/>
          </template>
        </v-card>
      </v-form>
    </v-dialog>


  </div>
</template>

<style scoped lang="scss">
.job-note-card {
  border-radius: 0;
  .subtitle {
    border-bottom: 1px solid #cecece !important;
    font-weight: initial;
  }
}
.wo-note-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 1;
  width: 100%;
  cursor: pointer;
}

.note-toolbar {
  a {
    color: #FFFFFF !important;
    text-decoration: none;
    background-color: #F57828 !important;
    border-radius: 3px;
    padding: 0 5px;
    box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
    &:hover {
      background-color: #eb6e1e !important;
    }
  }
}
</style>
