<template>
  <v-card flat elevation="4">
    <v-row wrap>
      <v-col sm="7" class="pl-6 pb-1">
        <v-card-title class="py-0">
          <v-chip-group
            v-model="selectedStatusIndexes"
            mandatory
            multiple
            selected-class="bg-blue-darken-1"
            class="align-center"
          >
            <h3 class="pr-8 pt-2">Filters</h3>
            <v-chip v-for="filterOption in filterStatuses" :key="filterOption" flat >
              {{ t(`workOrderStatuses.${filterOption}` , '') }}
            </v-chip>
          </v-chip-group>
          <!-- Right aligned things -->
        </v-card-title>
      </v-col>
      <v-col sm="5" class="pr-6 pb-1">
        <!-- Category -->
        <v-autocomplete
          :label="$t('category')"
          :items="categoryOptions"
          v-model="category"
          variant="underlined"
          clearable
          @update:model-value="fetchWorkOrders(0)"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-card>
  <v-data-table
    :headers="headers"
    :items="workOrderDays"
    :items-per-page-text="rowsPerPageText"
    :items-per-page-options="rowsPerPageItems"
    :class="$style.dashboard"
  >
    <template v-slot:top>
      <v-card flat class="d-flex align-center justify-center" :class="$style.top">
        <button @click="fetchWorkOrders(-1)" class="button"><v-icon>mdi-chevron-left</v-icon></button>
        <span :class="$style.week">Week {{ weekNr }}</span>
        <span>({{ dateTimeFormatByLocale(weekNrMonday, 'LLL') }} - {{ dateTimeFormatByLocale(weekNrSunday, 'LLL') }})</span>
        <button @click="fetchWorkOrders(1)" class="button"><v-icon>mdi-chevron-right</v-icon></button>
      </v-card>
    </template>
    <template v-slot:item="{ item }: {item: any}">
      <tr>
        <td v-for="(day) in daysOfWeek">
          <v-tooltip
            v-if="item[day]"
            location="top"
            width="300px"
            open-delay="300"
          >
            <template v-slot:activator="{ props }">
              <div v-bind="props" :class="$style.dayofweek">
                <router-link class="custom-a" :to="{ name: 'WorkOrdersView', params: { id: item[day].id } }">{{ dateTimeFormatByLocale(item[day].start, 'LT') }} {{ item[day].name }}</router-link>
                <span>{{ item[day].client }}</span>
                <span>{{ item[day].location }}</span>
                <span :class="$style.category">{{ item[day].category }}</span>
              </div>
            </template>
            <div :class="$style.orderinfo">
              <p>{{ dateTimeFormatByLocale(item[day].start, 'LT') }} {{ item[day].name }}</p>
              <p>{{ t(day) }} {{ dateTimeFormatByLocale(item[day].start, 'LLL') }}</p>
              <p>{{ t('timeStart') }}: {{ dateTimeFormatByLocale(item[day].start, 'LT') }}</p>
              <p>{{ t('status') }}: {{ t(`workOrderStatuses.${item[day].status}`) }}</p>
              <p v-if="item[day].contact">{{ t('contact') }}: {{ item[day].contact }}</p>
              <p v-if="item[day].phone"><v-icon size="small" class="mr-1">mdi-phone</v-icon>{{ item[day].phone }}</p>
              <p>{{ item[day].client }}</p>
              <p>{{ item[day].location }}</p>
              <p><b>{{ item[day].category }}</b></p>
            </div>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import moment from 'moment'
import { debounce } from 'lodash-es'

import { fetchActiveWorkOrders } from '@/api/workOrders'
import { dateTimeFormatByLocale, categoryItems } from '@/utils/filters'
import { datatableOptions } from '@/mixins/datatableOptions'
import useBranchSelector from '@/composable/useBranchSelector'
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n()

const { rowsPerPageText, rowsPerPageItems } = datatableOptions(true)
const { branchSelected } = useBranchSelector()

const weekNr = ref(moment().week())
const year = ref(moment().year())
const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
const workOrderDays = ref([] as any)
const category = ref(null as any)

// Week 07 is from Monday, February 12, 2024 until (and including) Sunday, February 18, 2024.
const weekNrMonday = computed(() => {
  return moment().year(year.value).week(weekNr.value).startOf('isoWeek')
})
const weekNrSunday = computed(() => {
  return moment().year(year.value).week(weekNr.value).endOf('isoWeek')
})

const filterStatuses = ref([
  'PLANNED',
  'ONGOING',
  'COMPLETED',
  'CANCELLED',
  'APPROVED',
])

let categoryOptions = categoryItems

const getHeaderTitle = (day: string, offset: number) => {
  const thisMonday = moment().year(year.value).week(weekNr.value).startOf('isoWeek')
  thisMonday.add(offset, 'days')
  return t(day) + ' (' + dateTimeFormatByLocale(thisMonday, 'L') + ')'
}

const headers: any = computed(() => [
  { title: getHeaderTitle('monday', 0), align: 'start', key: 'monday', sortable: false, width: '15%'},
  { title: getHeaderTitle('tuesday', 1), align: 'start', key: 'tuesday', sortable: false, width: '15%' },
  { title: getHeaderTitle('wednesday', 2), align: 'start', key: 'wednesday', sortable: false, width: '15%' },
  { title: getHeaderTitle('thursday', 3), align: 'start', key: 'thursday', sortable: false, width: '15%' },
  { title: getHeaderTitle('friday', 4), align: 'start', key: 'friday', sortable: false, width: '15%' },
  { title: getHeaderTitle('saturday', 5), align: 'start', key: 'saturday', sortable: false, width: '15%' },
  { title: getHeaderTitle('sunday', 6), align: 'start', key: 'sunday', sortable: false, width: '15%' },
])

const selectedStatusIndexes = ref((
  window.localStorage.getItem('workOrders/List/selectedStatusIndexes')
  ? window.localStorage.getItem('workOrders/List/selectedStatusIndexes')!.split(',').map(i => parseInt(i))
  : [0, 1, 2]
))

const selectedStatuses = computed(() => {
  return selectedStatusIndexes.value.map(
    index => filterStatuses.value[index]
  )
})

const fetchWorkOrders = async(index: number) => {
  // get new date
  let nextWeek = moment().year(year.value).week(weekNr.value).add(index, 'weeks')

  weekNr.value = nextWeek.week()
  year.value = nextWeek.year()
  const params = {
    branch_id: branchSelected.value,
    week_nr: weekNr.value,
    year: year.value,
    status: selectedStatuses.value.join(','),
    category: category.value
  }
  const result = await fetchActiveWorkOrders(params)

  // convert workOrders to days structure
  const workOrders = result.data.data
  // workOrders are sorted by start date descending
  const orderDays = result.data.data.reduce((acc: any, order: any) => {
    const day = moment(order.start).format('dddd').toLowerCase()
    if (!acc[day]) {
      acc[day] = []
    }
    acc[day].push(order)
    return acc
  }, {})

  const totalOrders = workOrders.length
  let nrOrders = 0

  // build tabel rows with orders per day
  workOrderDays.value = []
  while (nrOrders <= totalOrders) {
    const row = [] as any
    for (let i = 0; i < 7; i++) {
      const day = daysOfWeek[i]
      if (!row[day]) {
        row[day] = null
      }
      if (orderDays[day]) {
        const order = orderDays[day].pop()
        if (order) {
          row[day] = order
          nrOrders++
        }
      }
    }
    // because workOrders are sorted descending, final result here is ascending
    workOrderDays.value.push(row)
    if (nrOrders === totalOrders) {
      break
    }
  }
}

watch(branchSelected, () => {
  fetchWorkOrders(0)
})

watch(selectedStatusIndexes, debounce(function () {
  fetchWorkOrders(0)

  window.localStorage.setItem('workOrders/List/selectedStatusIndexes', selectedStatusIndexes.value.join(','))
}, 300)), { deep: true,  }

onBeforeMount(() => {
  fetchWorkOrders(0)
})

</script>

<style lang="scss" module>
    .dashboard th {
      font-weight: 800 !important;
      height: var(--custom-row-height) !important;
      background-color: var(--custom-bg) !important ;
    }
    .dashboard td {
      height: var(--custom-row-height)!important;
      vertical-align: top;
    }
    .dashboard button {
      margin: 8px 20px;
    }
    .dashboard span {
      font-weight: 500;
    }
    .dashboard .week {
      margin-right: 20px;
    }
    .dashboard .top {
      margin: 0;
    }

    .dashboard .dayofweek {
      padding: 4px 0;
    }
    .dashboard .dayofweek span {
      display: block;
      font-weight: 400;
    }
    .dashboard .dayofweek span.category {
      font-weight: 500;
    }
    .orderinfo p {
      font-size: 14px;
    }
</style>
