<script lang="ts" setup>
import {computed, inject, onMounted, provide, ref} from 'vue'
import {useI18n} from 'vue-i18n'
import Edit from './Edit.vue'
import Preview from './Preview.vue'
import {cloneDeep} from 'lodash-es';

// The string  translator
const {t} = useI18n()

// Get the API from the parent
const api = inject('api', null)
const apiId = inject('apiId', null)
const apiIdField = inject('apiIdField', null)

let isEditing = ref(false)
provide('file', isEditing)
provide('apiId', apiId)

let isPreviewing = ref()
provide('previewFile', isPreviewing)

// Is the user allowed to edit a file
// TODO: This will likely need to be a computed or otherwise set property
const allowEdit = ref(true)

// The File Input
const fileInput = ref(null as HTMLElement | null)

// The list of files
const files = ref([])


const imageFiles = computed(() => {
  return files.value?.filter((file) => file.image)
})
const documentFiles = computed(() => {
  return files.value?.filter((file) => !file.image)
})

onMounted(() => {
  documentsIndex()
})

/**
 * Load a document index from the api
 */
const documentsIndex = () => {
  api.value.index(apiId.value)
    .then((response) => {
      files.value = response.data.uploadedFiles
    })
    .catch((error) => {
      console.error(error)
    })
}

/**
 * Upload n documents to server
 * @param event
 */
const uploadDocument = (event: any) => {
  let form = new FormData()

  form.append(apiIdField, apiId.value)

  for (let file of event.target.files) {
    form.append('name[]', file.name)
    form.append('file[]', file)
  }

  api.value.upload(form)
    .then(() => {
      event.target.value = ''
      documentsIndex()
    })
    .catch((error) => {
      console.error(error)
    })
}

/**
 * Delete a File/Image
 * @param file
 */
const deleteDocument = (file: Object) => {
  if (confirm(t('documentDeleteConfirmation', 1))) {
    api.value.delete(apiId.value, file.id)
      .then(() => {
        documentsIndex()
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const documentUpdated = (updatedFile) => {
  // update the file that was changed
  for (const index in files.value) {
    if (files.value[index].id === updatedFile.id) {
      files.value[index] = cloneDeep(updatedFile);
    }
  }
  isEditing.value = false
}
const previewClosed = () => {
  isPreviewing.value = false
}

const previewDocument = (file: Object) => {
  isPreviewing.value = file
}

/**
 * Open a File/Image
 * @param file
 */
const openDocument = (file: Object) => {
  if(file.url) {
    return window.open(file.url, '_blank');
  }
  if (!file.id){
    return
  }
  api.value.show(apiId.value, file.id)
    .then((response) => {
      if(response.data?.url) {
        window.open(response.data?.url, '_blank');
      } else {
        throw Error('Cannot download file')
      }
    })
    .catch((error) => {
      alert(error)
    })
}
</script>

<template>
  <div class="documents-index">
    <Edit @updated="documentUpdated"/>
    <Preview @close="previewClosed"/>

    <input ref="fileInput" multiple type="file" @input="uploadDocument"/>
    <v-btn
      v-if="allowEdit"
      :text="t('documentUpload')"
      @click="fileInput.click()"
    />

    <div class="images-list">
      <template v-if="imageFiles.length">
        <h2>Images</h2>
        <div class="image-wrapper">
          <div
            v-for="file in imageFiles"
            :key="file"
            :title="`${file.name}.${file.extension}`"
            class="image-thumbnail"
            :class="{'with-category' : file.categoryName, 'broken-link': !file.url}"
          >
            <div
              class="el-icon el-icon-image"
              :style="{'background-image': `url('${file.url}')`}"
              @click="previewDocument(file)"
            ></div>
            <div
              v-if="file.categoryName"
              class="file-category"
            >
              <a v-if="file.categoryLink" target="_blank" :href="file.categoryLink">
                {{file.categoryName}}
              </a>
              <template v-else>{{file.categoryName}}</template>
            </div>

            <div class="file-title">
            <span>
              {{ file.name }}.{{ file.extension }}
            </span>
            </div>
            <div class="menu">
              <v-menu
                :offset="10"
                location="start center"
                open-on-hover
              >
                <template v-slot:activator="{ props, isActive }">
                  <v-icon
                    icon="true"
                    size="default"
                    v-bind="props"
                    class="edit-trigger"
                  >
                    {{ !isActive ? 'mdi-pencil' : 'mdi-close' }}
                  </v-icon>
                </template>
                <v-row class="bg-grey-lighten-2 rounded-xl">
                  <v-icon
                    v-if="allowEdit"
                    icon="mdi-link-circle"
                    class="ma-1"
                    color="blue"
                    size="large"
                    :disabled="!file.url"
                    @click="openDocument(file)"
                  ></v-icon>
                  <v-icon
                    v-if="allowEdit && !file.readOnly"
                    icon="mdi-pencil"
                    class="ma-1"
                    size="large"
                    @click.stop="isEditing = file"
                  ></v-icon>
                  <v-icon
                    v-if="allowEdit && !file.readOnly"
                    icon="mdi-delete-circle"
                    class="ma-1"
                    color="red"
                    size="large"
                    @click.stop="deleteDocument(file)"
                  ></v-icon>
                </v-row>
              </v-menu>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="documents-list">
      <template v-if="documentFiles.length">
      <h2>Files</h2>
      <div class="image-wrapper">
        <div
          v-for="file in documentFiles"
          :key="file"
          :title="`${file.name}.${file.extension}`"
          class="file-thumbnail"
          :class="{'with-category' : file.categoryName, 'broken-link': !file.url}"
        >
          <v-icon
            class="el-icon el-icon-document"
            icon="mdi-file-document-outline"
            @click="openDocument(file)"
          ></v-icon>
          <div class="file-title">
            <span>
              {{ file.name }}.{{ file.extension }}
            </span>
            <div
              v-if="file.categoryName"
              class="file-category"
            >
              <a v-if="file.categoryLink" target="_blank" :href="file.categoryLink">
                {{file.categoryName}}
              </a>
              <template v-else>{{file.categoryName}}</template>
            </div>

          </div>
          <div class="menu">
            <v-menu
              :offset="10"
              location="start center"
              open-on-hover
            >
              <template v-slot:activator="{ props, isActive }">
                <v-icon
                  icon="true"
                  size="default"
                  v-bind="props"
                  class="edit-trigger"
                >
                  {{ !isActive ? 'mdi-pencil' : 'mdi-close' }}
                </v-icon>
              </template>
              <v-row class="bg-grey-lighten-2 rounded-xl">
                <v-icon
                  v-if="allowEdit"
                  icon="mdi-link-circle"
                  class="ma-1"
                  color="blue"
                  size="large"
                  :disabled="!file.url"
                  @click="openDocument(file)"
                ></v-icon>
                <v-icon
                  v-if="allowEdit && !file.readOnly"
                  icon="mdi-pencil"
                  class="ma-1"
                  size="large"
                  @click.stop="isEditing = file"
                ></v-icon>
                <v-icon
                  v-if="allowEdit && !file.readOnly"
                  icon="mdi-delete-circle"
                  class="ma-1"
                  color="red"
                  size="large"
                  @click.stop="deleteDocument(file)"
                ></v-icon>
              </v-row>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.documents-index {
  input {
    display: none;
  }

  .images-list,
  .documents-list {
    margin-top: 15px;
  }

  .image-wrapper {
    display: flex;
    flex-wrap: wrap;

    &:before,
    &:after {
      content: "";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
      overflow: hidden;
    }

    .image-thumbnail,
    .file-thumbnail {
      display: flex;
      margin: 5px;

      position: relative;
      box-sizing: border-box;
      flex-grow: unset;
      width: calc(100% * (1/7) - 10px);
      background-color: white;
      box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));

      @media screen and (max-width: 1600px) {
        width: calc(100% * (1/5) - 10px);
      }
      @media screen and (max-width: 1280px) {
        width: calc(100% * (1/3) - 10px);
      }
      @media screen and (max-width: 800px) {
        width: calc(100% * (1/1) - 10px);
      }


      align-items: center;

      .el-icon-document{
        height: 80px;
        width: 60px;
        font-size: 50px;
      }


      .file-category {
        position: absolute;
        top:5px;
        right:5px;
        background-color: #F57828 !important;
        color: #FFFFFF !important;
        border-radius: 3px;
        padding:0 5px;
        box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));
        a {
          color: #FFFFFF !important;
          text-decoration: none;
        }
        &:hover {
          background-color: #eb6e1e !important;
        }
      }

      .file-title {
        width: 140px;
        font-size: 12px;
        padding-left:5px;
      }
      &.image-thumbnail {
        .file-title {
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            box-sizing: border-box;
            margin-right: 30px;
          }
        }
      }
      &.file-thumbnail {
        &.with-category {
          padding-bottom:15px;
        }
        .file-title {
          padding-top:10px;
          padding-bottom:10px;
        }
        .file-category {
          top: auto;
          bottom: 5px;
          right: auto;
          left: 5px;
        }
      }
      .menu {
        position: absolute;
        right: 7px;
        bottom: 7px;
      }

      .edit-trigger {
        border-radius: 50%;
        padding:5px;
        box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 1px 5px 0px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12));

      }
    }

    .image-thumbnail {
      display: block;

      .el-icon-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        aspect-ratio: 1.25;
        width: auto;
      }

      .file-title {
        width: auto;
        font-size: 12px;
        padding:5px;
      }
    }

  }
  .broken-link {
    .v-icon--clickable {
      cursor: not-allowed;
    }
  }
}
</style>
