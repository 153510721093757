<script setup lang="ts">
import {computed, defineEmits, inject, ref, watch} from 'vue';
import {jobEquipmentItemLocationAPI as API} from '@/api/jobs/jobEquipmentItemLocation'

import {useI18n} from 'vue-i18n'
import Datepicker from '@/components/UI/Datepicker.vue'
import {cloneDeep} from 'lodash-es'
import store from '@/store'
import sayErrorResponse from '@/mixins/sayErrorResponse'
import Say from '@/utils/Say'
import {Exception} from 'sass'
const { t } = useI18n()

const emit = defineEmits(["equipment-updated", "dialog-closed","show-template-ui","hide-template-ui"]);

const formSubmitted = ref(false)

const workOrderId = inject('workOrderId', null)

// Injected from Job/View
const jobId = inject('jobId', null)
API.setJobId(jobId.value)
const job = computed(() => store.getters['jobsEdit'])
const formatDate = inject('formatDate')
const vanAmerongenLocations = computed(() => store.getters['cache.vanAmerongenLocations'])
const rentalSuppliers = computed(() => store.getters['rentalSuppliersList'])

const tableFormat = inject('tableFormat', 0)

const items = inject('items', [])
const selectedItems = inject('selectedItems', [])
const selectedItemsData = computed(() => {
  return items.value.filter((item) => selectedItems.value.includes(item.id))
})

const selectedItemsList = computed(() => {
  let items = {};

  for (const item of selectedItemsData.value) {
    const key = `${item.name}___${item.rental_supplier_id}`
    if(!items[key]) {
      items[key] = {
        key: key,
        name: item.name,
        rental_supplier_name: item.rental_supplier_name,
        count: item.count ?? 1
      }
    } else {
      items[key].count += item.count ?? 1
    }
  }
  return items
})

const saveButtonDisabled = computed(() => {
  if(
    updateType.value === 'collect'
    && ((hasRentalSupplierItems.value > 0 && hasVAFItems.value > 0) || selectedRentalSuppliers.value.length > 1 || alreadyCollectedItemsCount.value > 0)
    && !allowPartialLocationMove.value
  ) {
    return true
  }
  // The default is to not disable the button
  return false
})
const allowPartialLocationMove = ref(false)
const selectedRentalSuppliers = computed(() => {
  let data = []
  for (const item of selectedItemsData.value) {
    let rsID = item.equipment_item?.rental_supplier_id ?? item.rental_supplier_id
    if(!rsID || data.includes(rsID)) continue
    data.push(rsID)
  }
  return data
})
const hasRentalSupplierItems = computed(() => {
  let data = []
  for (const item of selectedItemsData.value) {
    let rsID = item.equipment_item?.rental_supplier_id ?? item.rental_supplier_id
    if(!rsID) continue
    data.push(rsID)
  }
  return data.length
})
const hasVAFItems = computed(() => {
  let data = []
  for (const item of selectedItemsData.value) {
    let rsID = item.equipment_item?.rental_supplier_id ?? item.rental_supplier_id
    if(rsID) continue
    data.push(rsID)
  }
  return data.length
})
const alreadyCollectedItemsCount = computed(() => {
  let data = 0
  for (const item of selectedItemsData.value) {
    if(!item.collected_at) continue
    data++
  }
  return data
})

const locationOptions = computed(() => {
  let options = []

  let rentalSuppliersInList = []

  // Are there any of the selected equipment that is from a rental supplier
  for (const item of selectedItemsData.value) {
    let rsID = item.equipment_item?.rental_supplier_id ?? item.rental_supplier_id

    if(!rsID || rentalSuppliersInList.includes(rsID)) continue
    const selectedSupplier = rentalSuppliers.value.filter((supplier) => supplier.id === rsID)?.pop()

    if(!selectedSupplier) continue

    rentalSuppliersInList.push(selectedSupplier.id)
    options.unshift({
        id: selectedSupplier.location.id,
        city: `${selectedSupplier.name} | ${selectedSupplier.location.location}`
    })
  }

  let optionsVAF = cloneDeep(vanAmerongenLocations.value)
  for(const option of optionsVAF) {
    option.city = `VAF-Infra: ${option.city}`
    options.push(option)
  }
  return options
})

const jobWorkOrders = computed(() => {
  let data = [];
  for (const workOrder of job.value?.work_orders ? job.value.work_orders : []) {
    let name = `${workOrder.standard_name_prefix} | ${workOrder.category}`
    if(workOrder.datetime_start) {
      name = `${formatDate(workOrder.datetime_start, ' ')} | ${name}`
    }
    data.push({
      name: name,
      id: workOrder.id
    })
  }
  return data
})

const updateType = inject('updateType', null)
const postFormData = computed(() => {
  let data = {};
  if(updateType.value === 'rent_started_at') {
    data.rent_started_at = formData.value.rent_started_at ?? null
    data.rent_started_at = (formData.value.rent_started_at === 'Invalid date') ? null : formData.value.rent_started_at
  }
  if(updateType.value === 'rent_ended_at') {
    data.rent_ended_at = formData.value.rent_ended_at ?? null
    data.rent_ended_at = (formData.value.rent_ended_at === 'Invalid date') ? null : formData.value.rent_ended_at
  }

  if(updateType.value === 'collect') {
    data.location_id = formData.value.location_id
    data.work_order_id = formData.value.work_order_id
    data.location_change_type = 'collect'
  }

  // This need to be split between composite and non-composite items
  data.compositeItems = [];
  data.normalItems = [];
  if(tableFormat.value === 0) {
    for (const compositeItem of selectedItemsData.value.filter((item) => {return item.composite_identifier !== null})) {
      data.compositeItems.push(compositeItem.composite_identifier)
    }
    for (const normalItem of selectedItemsData.value.filter((item) => {return item.composite_identifier === null})) {
      data.normalItems.push(normalItem.id)
    }
    data.process_grouped = false
  } else {
    for (const compositeItem of selectedItemsData.value.filter((item) => {return item.composite_equipment_id !== null})) {
      data.compositeItems.push(compositeItem.HASH)
    }
    for (const normalItem of selectedItemsData.value.filter((item) => {return item.composite_equipment_id === null})) {
      data.normalItems.push(normalItem.HASH)
    }
    data.process_grouped = true
  }

  return data
})

const dialogTitle = computed(() => {
    if(updateType.value === 'rent_started_at') {
      return t('dialogBulkRentalStart')
    }
    if(updateType.value === 'rent_ended_at') {
      return t('dialogBulkRentalEnd')
    }
  return t('dialogBulk')
})

// Show the Collect Form
const updateDialogVisible = ref(false as boolean)

/**
 * The Form and all the references needed to keep track of that.
 */
const bulkUpdateForm = ref(null as HTMLFormElement | null)

// Keep track of the form validation state
const formValid = ref(true)

// The model keys being created/edited
const formData = ref({
  rent_started_at: null,
  rent_ended_at: null,
  work_order_id: workOrderId,
  location_id: null
})

/**
 * Form validation rules
 */
const formRules = ref({
  location_id: [
    value => {
      if (value) return true
      return t('pleaseFillInLocation')
    },
  ],
})


watch(
  () => [updateType.value],
  () => {
    updateDialogVisible.value = !!(updateType.value && selectedItems.value.length);
    if(updateType.value === 'collect') {
      store.dispatch('cache.vanAmerongenLocations.fetch');
      store.dispatch('rentalSuppliers.fetch');

      if(locationOptions.value.length > 0 && !formData.value.location_id) {
        if(job.value?.location_va?.id) {
          formData.value.location_id = job.value?.location_va?.id
        }
      }
    }
  }
)

watch(
  () => locationOptions.value,
  () => {
    if(locationOptions.value.length > 0 && !formData.value.location_id) {
      if(job.value?.location_va?.id) {
        formData.value.location_id = job.value?.location_va?.id
      }
    }
  }
)



const submitForm = () => {
  if(formSubmitted.value) return
  formSubmitted.value = true

  const data = postFormData.value;
  if (!data) {
    formSubmitted.value = false
    return alert(t('pleaseFillInAllFields'))
  }

  if(updateType.value === 'rent_started_at' && !data.rent_started_at) {
    formSubmitted.value = false
    return alert(t('pleaseFillInRentalStart'))
  }
  if(updateType.value === 'rent_ended_at' && !data.rent_ended_at) {
    formSubmitted.value = false
    return alert(t('pleaseFillInRentalEnd'))
  }

  if(updateType.value === 'collect') {
    if(!data.location_id) {
      let msg = [];
      if(!data.location_id) {
        msg.push(t('pleaseFillInLocation'))
      }
      if(msg.length > 0) {
        formSubmitted.value = false
        return alert(msg.join(', '))
      }
    }
  }


  API.bulkUpdate({
    ...data,
    ...{
      job_id: jobId?.value,
    }
  })
    .then((response) => {
      Say('success', t('equipmentItemEditSuccess', 1))
      emit("equipment-updated");
      closeDialog()
      formSubmitted.value = false
    })
    .catch((error) => {
      formSubmitted.value = false
      sayErrorResponse(error.response?.data?.message ?? error.message)
    })
}

const closeDialog = () => {
  updateType.value = null
  bulkUpdateForm.value.reset()
  formData.value.rent_started_at = null
  formData.value.rent_ended_at = null
  formData.value.work_order_id = workOrderId
  emit("dialog-closed");
}
</script>

<template>
  <div>
    <v-dialog
      v-model="updateDialogVisible"
      width="480"
      id="equipment-items-bulk-update-dialog"
      @afterLeave="closeDialog"
    >
      <v-form
        v-model="formValid"
        ref="bulkUpdateForm"
        @submit.prevent="submitForm"
      >
        <v-card
          prepend-icon="mdi-pencil"
          :title="dialogTitle"
        >
          <template v-slot:text>
            <v-table class="bulk-change-items-list mb-4" style="max-height: 212px">
              <thead>
              <tr>
                <th class="text-left no-wrap" width="50">{{t('Amount')}}</th>
                <th class="text-left no-wrap">{{t('Equipment')}}</th>
                <th class="text-left no-wrap">{{t('rentalSupplier')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in selectedItemsList"
                :key="item.key"
              >
                <td>{{ item.count }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.rental_supplier_name }}</td>
              </tr>
              </tbody>
            </v-table>

            <v-row v-if="['rent_started_at', 'rent_ended_at'].includes(updateType)">
              <v-col sm="6">
                <div v-if="updateType === 'rent_started_at'"  class="datepickerWrapper">
                  <div class="datepickerLabel">{{t('dateStart')}}</div>
                  <Datepicker
                    v-model="formData.rent_started_at"
                    :placeholder="t('dateStart')"
                    v-on:update:value-datetime="formData.rent_started_at = $event"
                    :teleport="false"
                  />
                </div>

                <div v-if="updateType === 'rent_ended_at'" class="datepickerWrapper">
                  <div class="datepickerLabel">{{t('dateEnd')}}</div>
                  <Datepicker
                    v-model="formData.rent_ended_at"
                    :placeholder="t('dateEnd')"
                    v-on:update:value-datetime="formData.rent_ended_at = $event"
                    :teleport="false"
                  />
                </div>
              </v-col>
            </v-row>

            <v-row v-if="['collect'].includes(updateType)">
              <v-col sm="12">
                <v-autocomplete
                  v-model="formData.work_order_id"
                  id="form-work_order_id"
                  :items="jobWorkOrders"
                  :label="t('workOrder')"
                  clearable
                  item-title="name"
                  item-value="id"
                  variant="underlined"
                  @change=""
                  autocomplete="off"
                  :disabled="!!workOrderId && formData.work_order_id === workOrderId"
                  />

                <v-autocomplete
                  v-model="formData.location_id"
                  :rules="formRules.location_id"
                  :items="locationOptions"
                  :label="t('PlaceEquipmentAtLocation')"
                  :placeholder="t('PlaceEquipmentAtLocation')"
                  item-title="city"
                  item-value="id"
                  variant="underlined"
                />

                <template v-if="alreadyCollectedItemsCount === selectedItemsData.length">
                  <v-alert
                    type="warning"
                    :title="t('warningAllItemsAlreadyCollected')"
                  />
                </template>
                <template
                  v-else-if="(hasRentalSupplierItems > 0 && hasVAFItems > 0) || selectedRentalSuppliers.length > 1 || alreadyCollectedItemsCount > 0"
                >
                  <v-alert
                    type="warning"
                  >
                    <p v-if="(hasRentalSupplierItems > 0 && hasVAFItems > 0) || selectedRentalSuppliers.length > 1">
                      {{t('warningMixedSupplierTypes')}}
                    </p>

                    <p v-if="alreadyCollectedItemsCount > 0">
                      {{t('warningSomeItemsAlreadyCollected')}}
                    </p>

                    <v-switch
                      hide-details
                      :label="t('warningMixedSupplierTypesMove')"
                      v-model="allowPartialLocationMove"
                    />
                  </v-alert>
                </template>
              </v-col>
            </v-row>

          </template>
          <template v-slot:actions>
            <v-btn
              :disabled="saveButtonDisabled"
              :text="t('save')"
              type="submit"
            />
            <v-btn
              :text="t('cancel')"
              type="reset"
              @click="closeDialog"
            />
          </template>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style scoped deep lang="scss">
  .bulk-change-items-list {
    &.v-table {
      .v-table__wrapper > table > tbody > tr > td,
      .v-table__wrapper > table > tbody > tr > th,
      .v-table__wrapper > table > thead > tr > td,
      .v-table__wrapper > table > thead > tr > th,
      .v-table__wrapper > table > tfoot > tr > td,
      .v-table__wrapper > table > tfoot > tr > th {
        padding:3px;
        height:auto;
      }
    }
  }
  .v-dialog#equipment-items-bulk-update-dialog > .v-overlay__content > .v-card,
  .v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > .v-sheet,
  .v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-card,
  .v-dialog#equipment-items-bulk-update-dialog  > .v-overlay__content > form > .v-sheet {
    overflow: initial;
  }
</style>
